import { Space, Statistic, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import Text from 'antd/lib/typography/Text'
import React, { useMemo } from 'react'

import { ProductSalesFieldsFragment } from '../generated/graphql'
import { Unpacked } from '../utils/typed'

type ProductItemModel = Unpacked<ProductSalesFieldsFragment['productItems']>
type ProductItemModelWithIndex = ProductItemModel & { index: number }

type Props = {
  productSales: ProductSalesFieldsFragment
}

export const ProductSalesSummary: React.FC<Props> = ({ productSales }) => {
  const dataSource = useMemo(() => {
    const source: ProductItemModelWithIndex[] = []
    productSales.productItems.forEach((productItem) => {
      productItem.productPriceItems.forEach((_, index) => {
        source.push({ ...productItem, index })
      })
    })
    return source
  }, [productSales.productItems])

  return (
    <Space direction="vertical" size="large" style={{ width: '100%' }}>
      <Space size="large">
        <Statistic title="売上" value={productSales.totalAmount} />
        <Statistic title="利用料金" value={productSales.totalCommissionFee} />
        <Statistic title="支払金額" value={productSales.paymentAmount} />
      </Space>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Text type="secondary">商品別集計</Text>
        <Table
          dataSource={dataSource}
          columns={columns}
          rowKey={(record) => `${record.productId}${record.index}`}
          pagination={false}
          style={{ width: '100%' }}
        />
      </Space>
    </Space>
  )
}

const columns: ColumnsType<ProductItemModelWithIndex> = [
  {
    title: '商品名',
    dataIndex: 'productName',
    render: (value, record) => ({
      children: value,
      props: {
        rowSpan: record.index === 0 ? record.productPriceItems.length : 0,
      },
    }),
  },
  {
    title: '売上',
    dataIndex: 'totalAmount',
    align: 'right',
    render: (_, record) => ({
      children: record.totalAmount.toLocaleString('ja-JP'),
      props: {
        rowSpan: record.index === 0 ? record.productPriceItems.length : 0,
      },
    }),
  },
  {
    title: '利用料金',
    dataIndex: 'commissionFee',
    align: 'right',
    render: (_, record) => ({
      children: record.totalCommissionFee.toLocaleString('ja-JP'),
      props: {
        rowSpan: record.index === 0 ? record.productPriceItems.length : 0,
      },
    }),
  },
  {
    title: '料金種別',
    dataIndex: 'productPriceName',
    render: (_, record) =>
      record.productPriceItems[record.index].productPriceName,
  },
  {
    title: '枚数',
    align: 'right',
    render: (_, record) =>
      record.productPriceItems[record.index].totalQuantity.toLocaleString(
        'ja-JP'
      ),
  },
]
